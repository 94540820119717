@if (!!ngLocation) {
  <div appProvideParentForm>
    <!-- Groupe de contacts -->
    <fieldset>
      <legend>Propriétaires</legend>

      <app-dossier-contacts-group-edit [link$]="contactsGroupLink$" [nameOffline]="ngLocation.bien.contactsGroup.nom"
                                       (changed)="onChangeContactsGroup($event)"/>
    </fieldset>

    <!-- Localisation -->
    <fieldset><legend>Localisation</legend><app-bien-edit-localisation [ngBienDossier]="ngLocation"/></fieldset>

    <!-- Description -->
    <fieldset><legend>Description</legend> <app-bien-edit-description [ngBienDossier]="ngLocation"/></fieldset>

    <!-- Loyer -->
    <fieldset>
      <legend>Loyer, charges et frais</legend>

      <!-- Principaux -->
      <div class="row">
        <!-- Loyer -->
        <div class="col-xs-12"
             [class]="ngLocation.bien.nature.code === DICTIONARY_ITEM_CODE_NATURE_LOCAUX_COMMERCIAUX ? 'col-sm-4' : 'col-sm-2'">
          <div class="form-group">
            <div [ngClass]="{'has-error': hasErrorOnConstraints(['loyer'])}">
              <label for="loyer">
                Loyer {{ ngLocation.bien.nature.code === DICTIONARY_ITEM_CODE_NATURE_LOCAUX_COMMERCIAUX ? ngLocation.typeLoyer.abreviation : '' }}
                *
              </label>
            </div>

            <div class="row">
              <div class="col-xs-12" [appFormError]="loyer"
                   [class]="ngLocation.bien.nature.code === DICTIONARY_ITEM_CODE_NATURE_LOCAUX_COMMERCIAUX ? 'col-sm-6' : 'col-sm-12'">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="loyer" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" class="form-control text-right"
                         [required]="ngLocation.statut === LOCATION_BROUILLON ? null! : 'true'"
                         name="loyer" [(ngModel)]="ngLocation.loyer" #loyer="ngModel" (input)="onChangeLoyerCharges()">

                  <span class="input-group-addon">€</span>
                </div>
              </div>

              @if (ngLocation.bien.nature.code === DICTIONARY_ITEM_CODE_NATURE_LOCAUX_COMMERCIAUX) {
                <div class="col-xs-12 col-sm-6">
                  <app-dictionary-select-items [model]="typeLoyer" [name]="DICTIONARY_NAME_LOCATION_LOYER_TYPES"
                                               [options]="{required: true}" (selected)="onSelectTypeLoyer($event)"/>
                </div>
              }
            </div>
          </div>
        </div>

        <!-- Charges -->
        <div class="col-xs-12 col-sm-offset-1 col-sm-4">
          <div class="form-group">
            <div [ngClass]="{'has-error': hasErrorOnConstraints(['charges'])}">
              <label for="charges">Charges</label>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-6" [appFormError]="charges">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="charges" pattern="^(?:\d*.)?\d+$" class="form-control text-right"
                         name="charges" [(ngModel)]="ngLocation.charges" #charges="ngModel"
                         (input)="onChangeLoyerCharges()">

                  <span class="input-group-addon">€</span>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6">
                <div class="checkbox clip-check check-primary checkbox-inline">
                  <input type="checkbox" id="charges-incluses" name="chargesIncluses"
                         [(ngModel)]="ngLocation.chargesIncluses" (change)="ngLocation.operateLoyer()">

                  <label for="charges-incluses">Incluses</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Encadrement du loyer -->
      <div class="row">
        <div class="col-xs-12">
          <div class="checkbox clip-check check-primary checkbox-inline">
            <input type="checkbox" id="encadrement-loyer" name="loyerEncadrement"
                   [(ngModel)]="ngLocation.loyerEncadrement">

            <label for="encadrement-loyer">Zone soumise à l'encadrement des loyers</label>
          </div>
        </div>

        @if (ngLocation.loyerEncadrement) {
          <div class="col-xs-12 tw:flex tw:gap-4 tw:sm:gap-8 tw:md:gap-20 tw:flex-wrap">
            <div class="form-group" [appFormError]="loyerReferenceMajore">
              <label for="loyer-reference-majore">Loyer de référence majoré *</label>

              <div class="input-group input-group-number input-group-currency">
                <input type="text" id="loyer-reference-majore" class="form-control text-right"
                       name="loyerReferenceMajore" required [(ngModel)]="ngLocation.loyerReferenceMajore"
                       #loyerReferenceMajore="ngModel" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$">

                <span class="input-group-addon">€</span>
              </div>
            </div>

            <div class="form-group loyer-base" [appFormError]="loyerBase">
              <label for="loyer-base">Loyer de base *</label>

              <soq-popover-template [display]="'default'" [placement]="'bottom'" [trigger]="'outsideClick'"
                                    [template]="'src/app/legacy/templates/locations/controllers/item/edit/general/conditions-loyer-base.help.html'"/>

              <div class="input-group input-group-number input-group-currency">
                <input type="number" id="loyer-base" class="form-control" name="loyerBase" required #loyerBase="ngModel"
                       [(ngModel)]="ngLocation.loyerBase" [max]="ngLocation.loyerReferenceMajore" min="0.01">

                <span class="input-group-addon">€</span>
              </div>
            </div>

            <div class="form-group" [appFormError]="loyerComplement">
              <label for="loyer-complement">Complément de loyer</label>

              <div class="input-group input-group-number input-group-currency">
                <input type="text" id="loyer-complement" class="form-control text-right" name="loyerComplement"
                       [(ngModel)]="ngLocation.loyerComplement" #loyerComplement="ngModel"
                       pattern="^(?:\d*.)?\d+$">

                <span class="input-group-addon">€</span>
              </div>
            </div>
          </div>
        }
      </div>

      <div class="row charges-locatives">
        <!-- Charges locataire -->
        <div class="col-xs-12 col-sm-6">
          <h5 class="h5-fieldset">À la charge du locataire</h5>

          <!-- Honoraires de rédaction -->
          <div class="row form-group">
            <div [ngClass]="{'has-error': hasErrorOnConstraints(['honoRedacChargeLocataire'])}"
                 class="col-xs-12 col-sm-6">
              <label for="hono-redac-charge-locataire">Honoraires de rédaction</label>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div [appFormError]="honoRedacChargeLocataire">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="hono-redac-charge-locataire" [(ngModel)]="ngLocation.honoRedacChargeLocataire"
                         class="form-control text-right" pattern="^(?:\d*.)?\d+$" #honoRedacChargeLocataire="ngModel"
                         name="honoRedacChargeLocataire" (input)="ngLocation.operateMontantTotalDu()">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          </div>

          <!-- État des lieux -->
          <div class="row form-group">
            <div [ngClass]="{'has-error': hasErrorOnConstraints(['etatLieuxChargeLocataire'])}"
                 class="col-xs-12 col-sm-6"><label for="etat-lieux-charge-locataire">État des lieux</label></div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div [appFormError]="etatLieuxChargeLocataire">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="etat-lieux-charge-locataire" [(ngModel)]="ngLocation.etatLieuxChargeLocataire"
                         class="form-control text-right" pattern="^(?:\d*.)?\d+$" #etatLieuxChargeLocataire="ngModel"
                         name="etatLieuxChargeLocataire" (input)="ngLocation.operateMontantTotalDu()">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Dépôt de garantie -->
          <div class="row form-group">
            <div class="col-xs-12 col-sm-6" [ngClass]="{'has-error': hasErrorOnConstraints(['depotGarantie'])}">
              <label for="depot-garantie">Dépôt de garantie</label>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div [appFormError]="depotGarantie">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="depot-garantie" pattern="^(?:\d*.)?\d+$" class="form-control text-right"
                         [(ngModel)]="ngLocation.depotGarantie" #depotGarantie="ngModel" name="depotGarantie"
                         (input)="ngLocation.operateMontantTotalDu()">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Montant total dû -->
          <div class="row form-group margin-top-30">
            <div class="col-xs-12 col-sm-6" [ngClass]="{'has-error': hasErrorOnConstraints(['montantTotalDu'])}">
              <label for="montant-total-du">Montant total dû</label>

              <soq-popover-template [display]="'default'" [placement]="'bottom'" [trigger]="'outsideClick'"
                                    [template]="'src/app/legacy/templates/locations/controllers/item/edit/general/calcul-montant-total-du.help.html'"/>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div [appFormError]="montantTotalDu">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="montant-total-du" pattern="^(?:\d*.)?\d+$" class="form-control text-right"
                         [(ngModel)]="ngLocation.montantTotalDu" #montantTotalDu="ngModel"
                         name="montantTotalDu" (input)="ngLocation.operateMontantVersementLocataire()">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Montant dû le premier mois -->
          <div class="row form-group margin-top-30">
            <div class="col-xs-12 col-sm-6"
                 [ngClass]="{'has-error': hasErrorOnConstraints(['montantVersementLocataire'])}">
              <label for="montant-verser-premier-mois">Montant dû le premier mois</label>

              <soq-popover-template [display]="'default'" [placement]="'bottom'" [trigger]="'outsideClick'"
                                    [template]="'src/app/legacy/templates/locations/controllers/item/edit/general/calcul-montant-premier-mois.help.html'"/>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div [appFormError]="montantVersementLocataire">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="montant-verser-premier-mois" [(ngModel)]="ngLocation.montantVersementLocataire"
                         pattern="^(?:\d*.)?\d+$" class="form-control text-right" #montantVersementLocataire="ngModel"
                         name="montantVersementLocataire">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Charges propriétaire -->
        <div class="col-sm-6">
          <h5 class="h5-fieldset">À la charge du propriétaire</h5>

          <!-- Honoraires de négociation -->
          <div class="row form-group">
            <div class="col-xs-12 col-sm-6"
                 [ngClass]="{'has-error': hasErrorOnConstraints(['honoNegoChargeProprietaire'])}">
              <label for="hono-nego-charge-proprietaire">Honoraires de négociation</label>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div [appFormError]="honoNegoChargeProprietaire">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" id="hono-nego-charge-proprietaire" #honoNegoChargeProprietaire="ngModel"
                         [(ngModel)]="ngLocation.honoNegoChargeProprietaire" pattern="^(?:\d*.)?\d+$"
                         class="form-control text-right" name="honoNegoChargeProprietaire">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Honoraires de rédaction -->
          <div class="row form-group">
            <div class="col-xs-12 col-sm-6"
                 [ngClass]="{'has-error': hasErrorOnConstraints(['honoRedacChargeProprietaire'])}">
              <label for="hono-redac-charge-proprietaire">Honoraires de rédaction</label>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4" [appFormError]="honoRedacChargeProprietaire">
              <div class="input-group input-group-number input-group-currency">
                <input type="text" [(ngModel)]="ngLocation.honoRedacChargeProprietaire" pattern="^(?:\d*.)?\d+$"
                       id="hono-redac-charge-proprietaire" class="form-control text-right"
                       #honoRedacChargeProprietaire="ngModel" name="honoRedacChargeProprietaire">

                <span class="input-group-addon">€</span>
              </div>
            </div>
          </div>

          <!-- État des lieux -->
          <div class="row form-group">
            <div class="col-xs-12 col-sm-6"
                 [ngClass]="{'has-error': hasErrorOnConstraints(['etatLieuxChargeProprietaire'])}">
              <label for="etat-lieux-charge-proprietaire">État des lieux</label>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-4">
              <div [appFormError]="etatLieuxChargeProprietaire">
                <div class="input-group input-group-number input-group-currency">
                  <input type="text" [(ngModel)]="ngLocation.etatLieuxChargeProprietaire" pattern="^(?:\d*.)?\d+$"
                         id="etat-lieux-charge-proprietaire" class="form-control text-right"
                         #etatLieuxChargeProprietaire="ngModel" name="etatLieuxChargeProprietaire">

                  <span class="input-group-addon">€</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Frais cautionnement -->
      <div class="form-group margin-top-30">
        <label for="frais-cautionnement">Frais de cautionnement</label>

        <textarea class="form-control" name="{{FIELDS.fraisCautionnement}}" id="frais-cautionnement"
                  [(ngModel)]="ngLocation.fraisCautionnement" [minHeight]="100"></textarea>
      </div>

      <!-- Champs cachés par défaut -->
      <button type="button" class="tw:btn-tertiary-info tw:p-2 tw:text-xs"
              (click)="showComplementLoyer = !showComplementLoyer">
        @if (showComplementLoyer) {
          <fa-icon [icon]="'caret-down'"/><span>Masquer les autres renseignements</span>
        } @else {
          <fa-icon [icon]="'caret-right'"/><span>Afficher les autres renseignements</span>
        }
      </button>

      <!-- Champs cachés -->
      @if (showComplementLoyer) {
        <div class="row margin-top-10">
          <!-- Versement du loyer -->
          <div class="col-xs-12 col-sm-3">
            <div class="form-group">
              <label for="loyer-versement">Versement du loyer</label>

              <input type="text" id="loyer-versement" class="form-control" name="designation"
                     [(ngModel)]="ngLocation.loyerVersement" placeholder="Ex : À l'étude / Au propriétaire">
            </div>
          </div>
          <!-- Périodicité du loyer -->
          <div class="form-group col-xs-12 col-sm-3">
            <label>Périodicité du loyer</label>

            <app-dictionary-select-items [model]="periodiciteLoyer" [name]="DICTIONARY_NAME_PERIODICITES"
                                         [options]="{required: true}" (selected)="onSelectPeriodiciteLoyer($event)"/>
          </div>

          <!-- Périodicité des charges -->
          <div class="form-group col-xs-12 col-sm-3">
            <label>Périodicité des charges</label>

            <app-dictionary-select-items [model]="periodiciteCharges" [name]="DICTIONARY_NAME_PERIODICITES"
                                         [options]="{required: true}" (selected)="onSelectPeriodiciteCharges($event)"/>
          </div>

          <!-- Droit au bail -->
          @if (ngLocation.bien.nature.code === DICTIONARY_ITEM_CODE_NATURE_LOCAUX_COMMERCIAUX) {
            <div class="col-sm-3 form-group"
                 [appFormError]="droitBail">
              <label for="droit-au-bail">Droit au bail</label>

              <div class="input-group input-group-number input-group-currency">
                <input type="text" [(ngModel)]="ngLocation.droitBail" pattern="^(?:\d*.)?\d+$" id="droit-au-bail"
                       class="form-control text-right" (input)="ngLocation.operateMontantVersementLocataire()"
                       #droitBail="ngModel" name="droitBail">

                <span class="input-group-addon">€</span>
              </div>
            </div>
          }
        </div>
      }
    </fieldset>

    <!-- Dossier -->
    <fieldset><legend>Dossier</legend> <app-bien-edit-dossier [ngBienDossier]="ngLocation"/></fieldset>
  </div>
}
