import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '@models/users/user/user.service';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import User from '@models/users/user/user.model';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {ModalService} from '@shared/modal/modal.service';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';
import {TransfersService} from '@models/transfers/transfers.service';
import {ContactsService} from '@models/contacts/contacts.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';

interface IMenu {
    execCode?: string,
    externalLink?: string,
    hasBadgeContact?: boolean,
    icon?: IconProp,
    imgPath?: string,
    isTaskRunner?: boolean,
    label: string,
    link?: string,
    phoneLink?: string,
    subMenus?: IMenu[],
    subMenusShow?: boolean,
}

@Component({selector: 'layout', standalone: false, templateUrl: 'layout.component.html'})
export class AppLayoutComponent implements OnDestroy, OnInit {
    static readonly execCodes = {
        CALCULATRICE: 'CALCULATRICE',
        IMPERSONNALISATION: 'IMPERSONNALISATION',
        SUPPORT_MAIL: 'SUPPORT_MAIL',
    };
    static readonly assistanceMenu: IMenu = {
        imgPath: 'assets/images/septeo/logo.svg',
        label: 'Assistance',
        subMenus: [
            {
                externalLink: 'https://espace-client.septeo.com',
                imgPath: 'assets/images/septeo/logo.svg',
                label: 'Espace client Septeo',
            },
            {
                execCode: AppLayoutComponent.execCodes.SUPPORT_MAIL,
                icon: ['far', 'envelope'],
                label: 'Contacter le support',
            },
            {phoneLink: 'tel:+33-4-67-15-44-20', icon: 'phone', label: '04 67 15 44 20'},
            {externalLink: 'https://assist.rg.gg', icon: 'ambulance', label: 'Prise en main'},
        ],
    };
    static readonly annuairesMenu: IMenu = {
        hasBadgeContact: true,
        icon: 'address-book',
        label: 'Annuaires',
        link: '/app/annuaires',
        subMenus: [
            {hasBadgeContact: true, icon: 'user', label: 'Contacts', link: '/app/annuaires/contacts'},
            {icon: ['fal', 'building-columns'], label: 'Études', link: '/app/annuaires/etudes'},
            {icon: 'users', label: 'Utilisateurs', link: '/app/annuaires/utilisateurs'},
        ],
    };
    static readonly dashboardMenu: IMenu = {icon: 'tachometer-alt', label: 'Tableau de bord', link: '/app/dashboard'};
    static readonly demandeursMenu: IMenu = {
        icon: 'crosshairs',
        label: 'Demandes',
        link: '/app/demandeurs',
        subMenus: [
            {label: 'Mon portefeuille', link: '/app/demandeurs/portefeuille'},
            {label: 'Nouvelle demande', link: '/app/demandeurs/item/edit/general'},
        ],
    };
    static readonly estimationsEvaluationMenu: IMenu = {label: 'Évaluation', link: '/app/estimations/informations'};
    static readonly estimationsMenu: IMenu = {
        icon: 'chart-line',
        label: 'Estimations',
        link: '/app/estimations',
        subMenus: [],
    };
    static readonly estimationsMonPortefeuilleMenu: IMenu = {
        label: 'Mon portefeuille',
        link: '/app/estimations/portefeuille',
    };
    static readonly estimationsNewMenu: IMenu = {
        label: 'Nouvelle estimation',
        link: '/app/estimations/item/edit/general',
    };
    static readonly estimationsReferencesArchiveesMenu: IMenu = {
        label: 'Références archivées',
        link: '/app/estimations/references/criteres',
    };
    static readonly globalMenu: IMenu = {icon: 'bars', label: 'Menus'};
    static readonly locationsMenu: IMenu = {
        icon: 'key',
        label: 'Locations',
        link: '/app/locations',
        subMenus: [
            {label: 'Mon portefeuille', link: '/app/locations/portefeuille'},
            {label: 'Nouvelle location', link: '/app/locations/item/edit/general'},
            {label: 'Diffusion multiple', link: '/app/locations/portefeuille/diffusion'},
        ],
    };
    static readonly profilAccountMenu = {label: 'Mon profil', link: '/app/my-account'};
    static readonly profilConfigurationMenu = {label: 'Configuration', link: '/app/configuration'};
    static readonly profilImpersonnalisationMenu = {
        execCode: AppLayoutComponent.execCodes.IMPERSONNALISATION,
        label: 'Impersonnalisation',
    };
    static readonly profilLogoutMenu = {label: 'Déconnexion', link: '/auth/logout'};
    static readonly profilMenu: IMenu = {label: 'Profil'};
    static readonly taskrunnerMenu: IMenu = {icon: 'download', isTaskRunner: true, label: 'Téléchargements'};
    static readonly toolboxCalculatriceMenu: IMenu = {
        execCode: AppLayoutComponent.execCodes.CALCULATRICE,
        icon: 'calculator',
        label: 'Calculatrice',
    };
    static readonly toolboxDiffusionsMenu: IMenu = {icon: 'rss', label: 'Diffusions', link: '/app/diffusions'};
    static readonly toolboxDocumentsMenu: IMenu = {
        icon: ['far', 'file'],
        label: 'Documents vierges',
        link: '/app/documents',
    };
    static readonly toolboxMenu: IMenu = {
        icon: 'toolbox',
        label: 'Boîte à outils',
        subMenus: [],
    };
    static readonly toolboxNewsMenu: IMenu = {icon: ['fat', 'newspaper'], label: 'Actualités', link: '/app/news'};
    static readonly toolboxProceduresMenu: IMenu = {
        icon: 'feather-alt',
        label: 'Signatures électroniques',
        link: '/app/procedures',
    };
    static readonly ventesMenu: IMenu = {
        icon: 'house',
        label: 'Biens à vendre',
        link: '/app/ventes',
        subMenus: [
            {label: 'Mon portefeuille', link: '/app/ventes/portefeuille'},
            {label: 'Nouveau bien à vendre', link: '/app/ventes/item/edit/general'},
            {label: 'Diffusion multiple', link: '/app/ventes/portefeuille/diffusion'},
        ],
    };
    private _apiImpersonationService = inject(ApiImpersonationService);
    private _browserOnlineService = inject(BrowserOnlineService);
    private _contactsService = inject(ContactsService);
    private _deviceDetectorService = inject(DeviceDetectorService);
    private _emailEditService = inject(EmailEditService);
    private _modalService = inject(ModalService);
    private _transfersService = inject(TransfersService);
    private _userService = inject(UserService);
    private _isDesktop!: boolean;
    private _leftMenusSource = new BehaviorSubject<IMenu[]>([]);
    private _leftMenus$ = this._leftMenusSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _profilMenu!: IMenu;
    private _rightMenusSource = new BehaviorSubject<IMenu[]>([]);
    private _rightMenus$ = this._rightMenusSource.asObservable();
    private _completeMenus$ = combineLatest([this._leftMenus$, this._rightMenus$]).pipe(map(([left, right]) => [...left, ...right]));

    get completeMenus$(): Observable<IMenu[]> {
        return this._completeMenus$;
    }

    get globalMenu(): IMenu {
        return AppLayoutComponent.globalMenu;
    }

    get isDesktop(): boolean {
        return this._isDesktop;
    }

    get leftMenus$(): Observable<IMenu[]> {
        return this._leftMenus$;
    }

    get nbInvalidContacts$(): Observable<number> {
        return this._contactsService.nbInvalid$;
    }

    get profilMenu(): IMenu {
        return this._profilMenu;
    }

    get rightMenus$(): Observable<IMenu[]> {
        return this._rightMenus$;
    }

    ngOnInit(): void {
        this._contactsService.initNbInvalid$();
        this._isDesktop = this._deviceDetectorService.isDesktop();
        this._userService.current$.pipe(
            filter(user => !!user),
            tap(user => {
                const estimationsSubMenus: IMenu[] = [];
                const menus: IMenu[] = [];

                if (user.hasRoleGrpVente()) {
                    estimationsSubMenus.push({...AppLayoutComponent.estimationsReferencesArchiveesMenu});
                }

                estimationsSubMenus.push({...AppLayoutComponent.estimationsMonPortefeuilleMenu});
                estimationsSubMenus.push({...AppLayoutComponent.estimationsNewMenu});
                if (!user.hasRoleGrpEstimationEvaluation()) {
                    estimationsSubMenus.push({...AppLayoutComponent.estimationsEvaluationMenu});
                }

                menus.push({...AppLayoutComponent.dashboardMenu});
                menus.push({...AppLayoutComponent.ventesMenu});
                menus.push({...AppLayoutComponent.locationsMenu});
                menus.push({...AppLayoutComponent.estimationsMenu, subMenus: estimationsSubMenus});
                menus.push({...AppLayoutComponent.demandeursMenu});

                this._leftMenusSource.next(menus);
            }),
            switchMap(user => {
                const menus: IMenu[] = [];
                const profilSubMenus: IMenu[] = [];
                const toolboxSubMenus: IMenu[] = [];

                this._profilMenu = {...AppLayoutComponent.profilMenu};
                this._profilMenu.imgPath = user.linkPhotoThumbnail;
                profilSubMenus.push({...AppLayoutComponent.profilAccountMenu});
                profilSubMenus.push({...AppLayoutComponent.profilConfigurationMenu});
                if (user.hasRoleAdmin()) {
                    profilSubMenus.push({...AppLayoutComponent.profilImpersonnalisationMenu});
                }

                profilSubMenus.push({...AppLayoutComponent.profilLogoutMenu});
                if (user.hasRoleGrpVente()) {
                    toolboxSubMenus.push({...AppLayoutComponent.toolboxCalculatriceMenu});
                }

                toolboxSubMenus.push({...AppLayoutComponent.toolboxNewsMenu});
                if (user.hasRoleGrpLocation() || user.hasRoleGrpVente()) {
                    toolboxSubMenus.push({...AppLayoutComponent.toolboxDiffusionsMenu});
                }
                if (user.hasRoleSignatureElectronique()) {
                    toolboxSubMenus.push({...AppLayoutComponent.toolboxProceduresMenu});
                }

                toolboxSubMenus.push({...AppLayoutComponent.toolboxDocumentsMenu});
                menus.push({...AppLayoutComponent.annuairesMenu});
                menus.push({...AppLayoutComponent.toolboxMenu, subMenus: toolboxSubMenus});
                menus.push({...AppLayoutComponent.assistanceMenu});
                menus.push({...this._profilMenu, subMenus: profilSubMenus});

                return this._transfersService.getNgTasks$().pipe(map(ngTasks => {
                    const rightMenus: IMenu[] = [...menus];

                    if (ngTasks.length > 0) {
                        rightMenus.unshift({...AppLayoutComponent.taskrunnerMenu});
                    }

                    this._rightMenusSource.next(rightMenus);
                }));
            }),
            takeUntil(this._onDestroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    execCode(code: string): void {
        this._browserOnlineService.checkAndPrevent$().pipe(
            switchMap(isOn => {
                if (!isOn) {
                    return of(undefined);
                }

                if (code === AppLayoutComponent.execCodes.CALCULATRICE) {
                    return this._modalService.ngOpen$('EskModalCalculator');
                } else if (code === AppLayoutComponent.execCodes.IMPERSONNALISATION) {
                    return this._modalService.ngOpen$<string>('EskModalImpersonation')
                        .pipe(map(username => this._apiImpersonationService.setImpersonate(username)));
                } else if (code === AppLayoutComponent.execCodes.SUPPORT_MAIL) {
                    return this._emailEditService.openAssistance$();
                }

                return of(undefined);
            }),
            take(1),
        ).subscribe();
    }

    isImpersonate$(): Observable<boolean> {
        return this._userService.currentHasRole$(User.roles.PREVIOUS_ADMIN);
    }

    stopImpersonate(): void {
        this._apiImpersonationService.razImpersonate();
    }
}
